<template>
  <section class="error-screen">
    <h1>404</h1>
    Oops... The page you are looking for does not exist.
  </section>
</template>

<script>
export default {
  name: "ErrorScreen",
  metaInfo: {
    titleTemplate: "%s | Buy Media, Better."
  }
};
</script>

<style scoped lang="scss">
.error-screen {
  @include displayFlex(column, center, center);
  @include defaultContainerPadding();
  margin-top: $header-size;
  height: calc(100vh - #{ $header-size });
}
</style>
